import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import { getUser, getUsers, createUser, deleteUser } from "../utils/auth"
import { navigate } from "gatsby"

export default function AdminPage() {
  const [users, setUsers] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const user = getUser()
    if (!user || !user.scopes.includes("admin")) {
      navigate("/")
    }
  })

  useEffect(() => {
    getUsers().then(list => {
      list && setUsers(list)
    })
  }, [])

  return (
    <Layout>
      <h2>Admin</h2>
      <hr />
      <h4>Add User</h4>
      <form
        name="addUser"
        method="post"
        onSubmit={async event => {
          event.stopPropagation()
          event.preventDefault()

          const {
            name: { value: name },
            email: { value: email },
            password: { value: password },
          } = event.target.elements

          event.target.reset()

          setIsSubmitting(true)

          await createUser({ name, email, password })

          setIsSubmitting(false)

          getUsers().then(list => {
            list && setUsers(list)
          })
        }}
        style={{ flex: 1, display: "flex", flexDirection: "row" }}
      >
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Name"
          disabled={isSubmitting}
          style={styles.inlineFormElement}
        />
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          disabled={isSubmitting}
          style={styles.inlineFormElement}
        />
        <input
          type="text"
          name="password"
          id="password"
          placeholder="Password"
          disabled={isSubmitting}
          style={styles.inlineFormElement}
        />
        <button
          type="submit"
          style={styles.submit}
          disabled={isSubmitting}
          style={{ ...styles.submit }}
        >
          Add User
        </button>
      </form>
      <hr />
      <h4>Users</h4>
      <table>
        <thead>
          <tr>
            <td>NAME</td>
            <td>EMAIL</td>
            <td>ADMIN</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.scopes.includes("admin").toString()}</td>
              <td>
                <button
                  style={styles.submit}
                  disabled={isSubmitting}
                  onClick={async event => {
                    setIsSubmitting(true)

                    await deleteUser({ id: user.id })

                    setIsSubmitting(false)

                    getUsers().then(list => {
                      list && setUsers(list)
                    })
                  }}
                >
                  DELETE
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Layout>
  )
}

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  submit: {
    borderRadius: "50px",
    padding: "0.2rem 1rem",
    alignSelf: "flex-end",
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: `"SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono",
    "Liberation Mono", Menlo, Courier, monospace`,
  },
  inlineFormElement: {
    flex: 1,
    margin: `0 1rem 0 0`,
  },
}
